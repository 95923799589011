const PropTypes = require('prop-types')
const {utils: {createReactElement}} = require('santa-components')

const id = 'RESPONSIVE_STYLES_RENDERER'
/**
 * @class components.ResponsiveStylesRenderer
 */
const ResponsiveStylesRenderer = ({renderable = null}) => createReactElement('div', {id, key: id}, renderable)

ResponsiveStylesRenderer.displayName = 'ResponsiveStylesRenderer'
ResponsiveStylesRenderer.compType = id
ResponsiveStylesRenderer.propTypes = {
    renderable: PropTypes.node
}

module.exports = ResponsiveStylesRenderer
